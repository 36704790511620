import {VisitService} from '../@core/visit.service';
import {APIService, VisitSerializer} from '../@core/api.service';
import {concat, Observable} from 'rxjs';
import {filter, map, shareReplay, tap} from 'rxjs/operators';
import {assignOrCreate, ModelBase} from './model-base';
import {PhysicianTeam, PhysicianTeamUser} from './physician-team';
import {getId} from '../utils/type.utils';
import {Task} from './models';
import {TaskProposal} from './task-proposal';
import {SimpleDatePipe} from '../@theme/pipes/simple-date.pipe';
import {toDateTime} from '../utils/date.utils';
import {DateTime} from 'luxon';

export const VISIT_TYPES = {
    'VISIT_PHYSICIAN': 'Physician Visit',
    'VISIT_NP': 'Nurse Practitioner Visit',
};
export const VISIT_TYPE_OPTIONS = Object.keys(VISIT_TYPES).map(k => ({value: k, label: VISIT_TYPES[k]}));
export type VisitStatus = 'CONFIRMED' | 'ACTIVE' | 'DISCARDED' | 'PRISTINE' | 'ALL';
export type VisitType = 'VISIT_PHYSICIAN' | 'VISIT_NP' | null;

export class Visit extends ModelBase implements Pick<VisitSerializer, Exclude<keyof VisitSerializer, 'physician'>> {
    id: number;
    date: Date | string;
    user_facility: number;
    done: boolean;
    physicianTeam: PhysicianTeam;
    physician: PhysicianTeamUser;
    task_count?: number;
    task_proposal_count?: number;
    task_saved_count?: number;
    secondary_assignees?: PhysicianTeamUser[];
    private visitService: VisitService;

    static viewSet = APIService.VisitViewSet;

    assign(visit: VisitSerializer, visitService?: VisitService, physicianTeam?: PhysicianTeam) {
        super.assign(visit);
        if (visitService) this.visitService = visitService;

        if (physicianTeam) {
            this.physicianTeam = physicianTeam;
            this.setAccessor('physicianTeam', PhysicianTeam);
            if (!this.physician && this.physicianTeam?.user) this.physician = this.physicianTeam.user;
            this.setAccessor('physician', PhysicianTeamUser);
            this.setListAccessor('secondary_assignees', PhysicianTeamUser);
        }
    }

    private _proposals$: Observable<TaskProposal[]>;
    private _proposalRequest$ = (visit: number, forceFetch?: boolean) => this.visitService.getProposals$({visit}, forceFetch).pipe(
        filter(proposals => !!proposals),
    );

    getProposals$(forceFetch?: boolean): Observable<TaskProposal[]> {
        if (!this._proposals$) {
            this._proposals$ = this._proposalRequest$(this.id);
        } else if (forceFetch) {
            return this._proposalRequest$(this.id, true);
        }
        return this._proposals$;
    }

    get isFuture() {
        return toDateTime(this.date).diff(DateTime.now().startOf('day')).milliseconds >= 0;
    }

    get isPhysician() {
        return getId(this.physicianTeam.user) === getId(this.physician);
    }

    get searchText() {
        return [this.physician?.name, SimpleDatePipe.transform(this.date, false), this.physicianTeam?.facility?.name].filter(x => x).join(' ');
    }

    get isPrimaryPhysicianVisit(): boolean {
        return this.physicianTeam.user.id === this.physician.id;
    }

    get colorClass() {
        if (this.task_count === 0) return 'secondary';
        if (this.task_saved_count === this.task_count && this.task_proposal_count === 0) return 'success';
        return 'primary';
    }

    isType(type: VisitType) {
        switch (type) {
            case 'VISIT_PHYSICIAN':
                return this.isPhysician;
            case 'VISIT_NP':
                return !this.isPhysician;
            default:
                return true;
        }
    }

    private _tasks$: Observable<Task[]>;

    getTasks$(forceFetch?: boolean) {
        if (!this._tasks$ || forceFetch) {
            let tasks: Task[];
            this._tasks$ = concat(
                APIService.TaskSchedulerViewSet.list({visit: this.id}).pipe(
                    map(resp => resp.results.map(x => assignOrCreate(Task, x))),
                    tap(x => tasks = x),
                ),
                this.visitService.ccm.taskUpdates$.pipe(
                    map(x => {
                        if (x.task.visit === this.id && x.event === 'add') {
                            tasks = [x.task as Task, ...tasks];
                        } else if (x.event === 'remove') {
                            tasks = tasks.filter(t => t.id !== x.task.id);
                        } else if (x.event === 'update') {
                            const wasInVisit = tasks.some(visitTask => visitTask.id === x.task.id);
                            if (wasInVisit && x.task.visit !== this.id) {
                                tasks = tasks.filter(t => t.id !== x.task.id);
                            } else if (!wasInVisit && x.task.visit === this.id) {
                                tasks = [...tasks, (x.task as Task)];
                            }
                        }
                        return tasks;
                    }),
                ),
            ).pipe(shareReplay(1));
        }
        return this._tasks$;
    }

    static proposalStatusToConfirmed(status: VisitStatus): boolean {
        switch (status) {
            case 'PRISTINE':
                return null;
            case 'CONFIRMED':
                return true;
            case 'DISCARDED':
                return false;
            default:
                return undefined;
        }
    }

    getSerialized() {
        // TODO: Visit might have to be serialized properly for future use cases
        return this.id;
    }
}
