import {Reviewable} from '../models/models';
import {Observable, OperatorFunction} from 'rxjs';
import {map} from 'rxjs/operators';
import {ReviewTypeKey} from './definitions';
import {assignOrCreate, ModelBase, ModelType} from '../models/model-base';
import {Type} from '@angular/core';
import {capitalizeAll} from '../utils/string.utils';
import {WithPageDetails} from '../portal/@slide-panel-preview/patient-details/patient-details.component';
import {SlidePanelOptions} from '../portal/slide-panel/slide-panel';
import {Data, Route, UrlMatcher, UrlSegment} from '@angular/router';
import {TaskCreateSerializer} from '../@core/api.service';
import {EntryAction} from '../models/entry-note';

export class ContentDefinition<T extends ModelBase = any> {
    key: string;
    name: {lowerCase: string; capitalized: string};
    icon: string;
    path?: string;
    pathStringParams?: string[];
    loadComponent?: () => Promise<Type<any>>;
    loadChildren?: () => Promise<Type<any>>;
    modelBase?: typeof ModelBase;
    actionEntryKey: keyof EntryAction;
    permissions?: string[];
    patientDetailsSubPath?: string;

    constructor(x: Partial<ContentDefinition<T>>) {
        this.key = x.key;
        this.name = {
            lowerCase: x.key.split('_').map(w => w.toLowerCase()).join(' '),
            capitalized: capitalizeAll(x.key),
        };

        Object.assign(this, x);
    }

    getPath(...paramVals: (number | string)[]) {
        if (!this.path) return null;
        const paramSegments = this.path.split('/').filter(x => x.startsWith(':'));
        return paramSegments.reduce((path, segment, i) => path.replace(segment, paramVals[i]?.toString()), `/${this.path}`);
    }

    processItem(x): T {
        return this.modelBase ? assignOrCreate(this.modelBase, x) : x;
    }
}

export class SmartLinkDefinition<T extends ModelBase = any> extends ContentDefinition<T> {
    loadComponent?: () => Promise<Type<WithPageDetails>>;
    getLink?: (data: T) => string;
    getFragment?: (data: T) => string;
    hasUpdateSubject = false;
    getColorClass?: (data: T) => string;
    getColor?: (data: T) => string;
    getIcon?: (data: T) => string;
    getDate?: (data: T) => Date | string;
    getPre?: (data: T) => string;
    getPost?: (data: T) => string;
    getLabel?: (data: T) => string;
    getOpenDetailsText?: (data: T) => string;
    getTooltip?: (data: T) => string;
    action?: (data: T) => void;
    fade?: boolean;

    constructor(options: Partial<SmartLinkDefinition<T>>);
    constructor(base: ContentDefinition<T>, options?: Partial<SmartLinkDefinition<T>>);
    constructor(baseOrOptions: ContentDefinition<T> | Partial<SmartLinkDefinition<T>>, options?: Partial<SmartLinkDefinition<T>>) {
        super({key: baseOrOptions.key});

        Object.assign(this, baseOrOptions);

        this.getOpenDetailsText = () => `See ${this.name.capitalized} details`;

        if (options) Object.assign(this, options);

        if (!this.getLink && this.path) {
            const paramKeys = this.path.split('/').filter(x => x.startsWith(':')).map(x => x.slice(1));
            this.getLink = x => this.getPath(...paramKeys.map(key => x[key]));
        }
    }
}

export class ReviewDefinition<T extends Reviewable = any> extends ContentDefinition<T> {
    key: ReviewTypeKey;
    taskTypeKey: string;
    reviewFieldKey: keyof TaskCreateSerializer;
    getPatientId?: (entry: T) => number;
    caption: string;
    getReviewEndpoint: (entry: T) => (...args: any[]) => Observable<any>;
    modelBase?: ModelType<T>;
    pipeOperator?: OperatorFunction<any, T>;

    constructor(base: ContentDefinition<T>, options: Partial<ReviewDefinition<T>>) {
        super(base);

        this.caption = this.name.lowerCase;

        Object.assign(this, options);

        this.pipeOperator = map(x => this.processItem(x));
    }
}

export type SlidePanelSize = 'max' | 'xl' | 'lg' | 'md' | 'sm';
export type SlidePanelVerticalAlign = 'top' | 'center';

export interface SlidePanelConfig {
    hasHeading: boolean;
}

export class SlidePanelDefinition extends ContentDefinition implements SlidePanelOptions {
    component?: Type<any>;
    defRootUrl?: string;
    config: SlidePanelConfig = {
        hasHeading: false,
    };
    size?: SlidePanelSize = 'md';
    verticalAlign?: SlidePanelVerticalAlign;
    showFab?: boolean;
    nextPrevBtnText?: string[];
    customMarkup = false;

    constructor(options: Partial<SlidePanelDefinition>);
    constructor(base: ContentDefinition, options?: Partial<SlidePanelDefinition>);
    constructor(baseOrOptions: ContentDefinition | Partial<SlidePanelDefinition>, options?: Partial<SlidePanelDefinition>) {
        super({key: baseOrOptions.key});

        Object.assign(this, baseOrOptions);

        this.nextPrevBtnText = [`Previous ${this.name.capitalized}`, `Next ${this.name.capitalized}`];

        if (options) Object.assign(this, options);
    }

    getRoute(): Route {
        const data = {
            matcherPath: this.path,
            matcherPathStringParams: this.pathStringParams,
        };

        if (this.loadChildren) {
            return {
                loadChildren: this.loadChildren,
                matcher: paramMatcher,
                data,
            };
        }

        return this.getChildRoute(this.component, paramMatcher, data);
    }

    getChildRoute(component: Type<any>, matcher?: UrlMatcher, routeData: Data = {}, path = ''): Route {
        const route: Route = {
            component,
            data: {
                ...routeData,
                slidePanel: true,
                defRootUrl: this.defRootUrl,
                slidePanelConfig: this.config,
                slidePanelOptions: {
                    size: this.size,
                    verticalAlign: this.verticalAlign,
                    showFab: this.showFab,
                    nextPrevBtnText: this.nextPrevBtnText,
                    customMarkup: this.customMarkup,
                } as SlidePanelOptions,
            },
        };
        if (matcher) {
            route.matcher = matcher;
        } else {
            route.path = path;
        }
        return route;
    }
}

export const paramMatcher: UrlMatcher = (segments, _, route) => {
    const pathSegments = route.data.matcherPath.split('/').map(s => {
        const paramName = s.startsWith(':') ? s.slice(1) : null;
        return {
            path: s,
            paramName,
            stringParam: paramName ? route.data.matcherPathStringParams?.includes(paramName) : false,
        };
    });

    if (pathSegments.length !== segments.length) return null;

    const posParams: {[name: string]: UrlSegment} = {};

    for (let i = 0; i < pathSegments.length; i++) {
        const ps = pathSegments[i], us = segments[i];

        if (!ps.paramName) {
            if (ps.path !== us.path) return null;

            continue;
        }

        if (!ps.stringParam && !Number.isInteger(+us.path)) return null;

        posParams[ps.paramName] = us;
    }

    return {consumed: segments, posParams};
};

export const getIntegerIdMatcher = (basePath: string, idParamName = 'id'): UrlMatcher => (segments: UrlSegment[]) => {
    const basePathSegments = [...segments];
    const idSegment = basePathSegments.pop();

    return basePathSegments.map(x => x.path).join('/') === basePath && Number.isInteger(+idSegment?.path) ?
        {consumed: segments, posParams: {[idParamName]: idSegment}} :
        null;
};
