import {ModelBase} from './model-base';
import {APIService, FacilityOriginSimpleSerializer, FacilitySimpleSerializer, HL7MessageListSerializer, PatientSimpleSerializer} from '../@core/api.service';

export class Hl7Message extends ModelBase implements HL7MessageListSerializer {
    id: number;
    created_at: Date;
    modified_at: Date;
    status: string;
    facility: FacilitySimpleSerializer;
    patient: PatientSimpleSerializer;
    type: string;
    message_id: string;
    error_code: string;
    facility_origin: FacilityOriginSimpleSerializer;
    file_name?: string;
    file_location?: string;
    error_message?: string;
    object_id?: number;
    is_archive?: boolean;
    sftp_username?: string;

    static viewSet = APIService.HL7MessageViewSet;

    constructor(message) {
        super(message);
        if (typeof message.created_at === 'string') this.created_at = new Date(message.created_at);
        if (typeof message.modified_at === 'string') this.modified_at = new Date(message.modified_at);
    }
}
